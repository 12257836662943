.wrapper {
  & header {
    @apply relative flex items-center border-b border-black-100/25 pb-2 mb-2;

    & h3 {
      @apply text-lg md:text-xl;
    }

    & :global(.tooltip) {
      @apply relative;
      & > svg {
        @apply block w-6 h-6 ml-2 cursor-pointer;
      }

      &:global(.active) {
        &::before {
          @apply absolute content-[""] border-solid border-transparent;

          @apply left-5 -bottom-4 -translate-x-1/2;
          @apply border-l-10 border-r-10 border-b-10 border-t-0;
          @apply border-b-black-100;
        }
        &:global(.right) {
          &::before {
            @apply left-10 bottom-auto top-1/2 -translate-x-0 -translate-y-1/2;
            @apply border-l-0 border-r-0 border-b-0;
            @apply border-t-10 border-r-10 border-b-10;
            @apply border-b-transparent border-r-black-100;
          }
        }
      }
    }

    & :global(.edit-link),
    & button {
      @apply ml-auto flex items-center cursor-pointer underline text-base font-bold;
      & svg {
        @apply block w-4 h-4 ml-2;
      }
    }
  }

  & > p {
    @apply text-base md:text-lg;
    & a {
      @apply underline;
    }
  }
}
