.wrapper {
  &:global(.top-padding) {
    @apply pt-6 md:pt-8;
  }

  &:global(> .inner-wrapper) {
    @apply bg-white p-6 md:p-8 rounded-lg;
  }

  & :global(.info-panels) {
    @apply mt-6 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-6 gap-x-10;
  }

  & :global(.cta-wrapper) {
    @apply bg-summer-green-23 rounded-xl p-4 md:p-8 mt-8 overflow-hidden;

    & h3 {
      @apply text-xl md:text-2.5xl mb-2;
    }

    & p {
      @apply text-base;
    }

    & a {
      @apply font-bold underline;
    }
  }

  & :global(.debug-wrapper) {
    @apply bg-dark-cyan-50 rounded-xl p-4 md:p-8 mt-8 overflow-hidden;

    & h3 {
      @apply text-lg mb-4;
    }

    & pre {
      @apply bg-dark-cyan-25 rounded-lg p-4 text-sm;
    }

    & p {
      @apply text-sm;
    }
  }
}
