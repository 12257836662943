.modal {
  @apply absolute rounded-lg bottom-auto right-auto bg-white top-1/2 left-1/2 max-w-4xl;
  @apply outline-none max-h-[95%] h-[95%] md:h-auto;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);

  & :global(.content-wrapper) {
    @apply flex flex-col flex-grow p-6 md:p-8 sm:p-10 relative max-h-full;
  }

  & h2 {
    @apply pb-3 max-w-[85%];
  }

  & h3 {
    @apply pb-3 text-2xl;

    & span {
      @apply inline-block align-middle ml-4 text-base font-sans underline cursor-pointer;
    }
  }

  & :global(.button-wrapper) {
    @apply flex items-center justify-between md:justify-start md:space-x-6;
  }

  & :global(.button) {
    @apply text-lg py-2.5 px-5 font-serif font-bold;
    @apply inline-block w-[calc(50%-10px)] md:w-auto;
  }

  & :global(.click) {
    @apply absolute w-10 h-10 outline-none -right-5 -top-5;
    @apply z-10 flex items-center justify-center;
  }

  & :global(.close) {
    @apply w-6 h-6 text-white rounded-full cursor-pointer bg-black-100;
    @apply flex items-center justify-center relative z-10;

    & :global(.icon) {
      @apply fill-white w-[10px] relative z-10;
    }
  }

  & :global(.options-wrapper) {
    @apply grid gap-x-4 grid-cols-1 lg:grid-cols-2;
  }

  & :global(.selected-wrapper) {
    @apply mb-4;
    @apply overflow-y-scroll flex-grow;
    @apply lg:max-h-64;

    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  }

  & :global(.selected) {
    @apply inline-block py-1.5 px-2 mr-2 mb-2 bg-summer-green-23 rounded font-bold uppercase text-xs tracking-[1px];
    @apply cursor-pointer;
    & svg {
      @apply inline-block w-2 h-2 fill-current stroke-current ml-2;
    }
  }
}

.select {
  @apply relative select-none h-14 mb-6;

  &:global(.is-open) {
    @apply z-40;

    & :global(header svg) {
      @apply transform rotate-180;
    }
  }

  & :global(.checkboxes) {
    @apply absolute top-0 left-0 w-full bg-white border border-black-100/25 rounded;

    &:global(.has-shadow) {
      @apply shadow-md;
    }
  }

  & :global(header) {
    @apply flex items-center justify-between px-4 py-3 cursor-pointer;

    & :global(.current-choice) {
      @apply text-lg leading-none pointer-events-none;

      & span {
        @apply block pb-1 font-sans text-xs font-bold leading-none tracking-widest;
      }
    }

    & :global(.clear-filter) {
      @apply flex items-center;

      & :global(.square) {
        @apply flex items-center justify-center w-5 h-5 mr-3 rounded bg-summer-green-100;
      }
    }

    & :global(svg) {
      @apply w-[11px] h-auto pointer-events-none transition-transform duration-[100];
    }
  }

  & :global(.open-filter) {
    @apply relative z-30 bg-white rounded-b overflow-hidden;

    & :global(.overlay) {
      @apply absolute w-full h-full max-h-60 md:max-h-80 z-40 pointer-events-none;
      &:after {
        @apply absolute bottom-0 left-0 h-4 rounded-b content-[""];
        width: calc(100% - 4px);
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #fff
        );
      }
    }

    & :global(ul) {
      @apply pl-4 overflow-y-scroll max-h-60 md:max-h-80 relative;

      & li {
        @apply mb-3 text-base;
      }

      &::-webkit-scrollbar {
        @apply w-1;
      }
      &::-webkit-scrollbar-track {
        @apply bg-black-25;
      }
      &::-webkit-scrollbar-thumb {
        @apply bg-black-100;
      }
      &::-webkit-scrollbar-thumb:hover {
        @apply bg-black-75;
      }
    }

    & :global(.button-wrapper) {
      @apply px-4 pb-3 mt-2;

      & :global(.button) {
        @apply w-full font-sans;
        &:global(.dim) {
          @apply opacity-20;
        }
      }
    }
  }

  & :global(.checkbox) {
    @apply flex items-center cursor-pointer;
    & :global(.square) {
      @apply w-6 h-6 border rounded flex items-center justify-center border-black-100/25;
    }

    &:global(.is-child) {
      @apply ml-4;
    }

    &:global(.is-checked .square) {
      @apply bg-summer-green-100 border-summer-green-100;

      & :global(.dot) {
        @apply w-1.5 h-1.5 bg-black-100 rounded-full;
      }
    }

    & :global(.label-wrapper) {
      @apply flex items-center justify-between w-full pr-4;
    }

    & :global(.label) {
      @apply pl-3 max-w-[85%];
    }
  }
}
