.card {
  @apply col-span-1 text-black-100 bg-white pointer-events-auto cursor-pointer rounded;

  & :global(.inner-wrapper) {
    @apply relative flex flex-col justify-between overflow-hidden rounded shadow-md;
  }

  & :global(.header) {
    @apply flex items-center py-2.5 px-4 text-left;

    & :global(.text-wrapper) {
      max-width: calc(100% - 50px);
      @apply text-xs;

      & :global(.organizer) {
        @apply text-sm font-bold block;
      }
    }

    & svg {
      @apply mr-2.5 relative bg-summer-green-100/50 p-1;
      height: 100%;
      width: min-content;
      max-width: 40px;
    }
  }

  & :global(.image-wrapper) {
    @apply relative aspect-w-16 aspect-h-12 bg-black-100/10;
  }

  &:hover {
    &:global(.content-wrapper) {
      @apply overflow-visible;
    }
  }

  & :global(.content-wrapper) {
    @apply relative bg-white text-left;

    & :global(.motion-elem) {
      @apply px-4 pt-3 bg-white;
      @apply inset-x-0 top-0 z-10 pb-2 md:pb-0;
      @apply md:absolute;
    }

    & :global(.tag) {
      @apply text-xs font-bold tracking-wide uppercase leading-4.5;
    }

    & :global(h3) {
      @apply py-1 font-sans font-medium;
      @apply text-lg lg:text-xl line-clamp-2;
      @apply leading-snug lg:leading-snug;
    }

    & :global(p) {
      @apply text-sm line-clamp-4;
    }
  }

  & :global(.footer-wrapper) {
    @apply z-10 flex items-center justify-between bg-white border-t border-black-100/10;
    @apply pl-4 text-sm font-serif font-bold;

    & :global(.arrow) {
      @apply h-12 aspect-1 bg-summer-green-100/50 flex items-center justify-center;

      & svg {
        @apply w-5.5 h-auto;
      }
    }
  }
}
