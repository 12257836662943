.terms {
  @apply box-content flex flex-wrap mx-auto max-w-screen-sm;

  & li {
    @apply mb-4 mr-3;
  }

  & :global(.term) {
    @apply font-sans text-xs font-medium tracking-wider uppercase leading-none whitespace-nowrap;
    @apply px-3 py-2 rounded bg-black-100/10 hover:bg-black-100/20;
    @apply no-underline !important;
  }
}
