.wrapper {
  @apply max-w-8xl box-content px-4 mx-auto md:px-8 pt-6 md:pt-8;

  & form {
    @apply bg-white shadow-md-sm p-6 md:p-8;
    @apply flex flex-col md:flex-row;

    & > * {
      @apply mb-5 md:mb-0;
    }

    & button {
      @apply mb-0;
    }
  }

  & :global(.search-input) {
    @apply flex items-center px-4 border rounded border-black-100/25 h-15;
    @apply md:mr-5 w-full;

    & input {
      @apply w-full h-full overflow-hidden text-lg focus:outline-none bg-white;
      @apply placeholder:text-base;
    }

    & :global(.magnifier) {
      @apply w-5 mr-4 fill-current stroke-current;
    }
  }

  & :global(.select-wrapper) {
    @apply relative block px-0 mx-0 md:mr-5 font-sans text-lg font-normal text-left rounded;
    @apply border border-black-25 h-15 md:mb-0 text-black-100;

    & :global(.caret) {
      @apply w-[11px] h-auto absolute top-0 right-0 mt-6.5 mr-4 pointer-events-none;
    }

    & label {
      @apply absolute z-10 font-sans text-xs font-bold leading-none tracking-widest pointer-events-none left-5 top-3;
    }

    & select {
      @apply absolute top-0 left-0 block w-full h-full text-lg leading-none outline-none appearance-none focus:outline-none;
      @apply pt-4 pl-5 pr-9 overflow-hidden whitespace-nowrap overflow-ellipsis rounded bg-white;
    }

    @screen md {
      flex: 1 1 50%;
    }
    @screen lg {
      min-width: 160px;
    }
  }

  & :global(.search-button) {
    @apply min-w-[130px];
  }
}
