.details {
  @apply p-4 sm:p-8 flex w-full;

  & :global(.inner-wrapper) {
    @apply flex flex-col sm:flex-row overflow-y-auto pb-4 w-full;
  }

  & :global(.figure-wrapper) {
    @apply sm:mr-8 mb-4;
  }

  & figure {
    @apply w-full sm:w-[360px] relative pt-0 mb-0 h-auto aspect-1 overflow-hidden bg-black-100/10;
  }

  & :global(.position) {
    @apply mb-2.5 block font-sans break-words;
  }

  & :global(.full-name) {
    @apply font-sans text-2xl font-medium;
  }

  & :global(.content-wrapper) {
    @apply flex-grow;
  }

  & :global(.description) {
    @apply py-3 text-base;
  }

  & :global(.link) {
    @apply pb-2;
  }

  & a {
    @apply underline;
  }
}
