.controller {
  @apply h-15 w-full rounded;
  @apply relative;

  &:global(.is-open) {
    @apply z-40;
  }

  & :global(.radios) {
    @apply absolute top-0 left-0 w-full bg-white border border-black-25 rounded;

    &:global(.has-shadow) {
      @apply shadow-md;
    }
  }

  & header {
    @apply flex flex-row items-center justify-between px-4 py-3 cursor-pointer;

    & :global(.current-choice) {
      @apply text-lg leading-none pointer-events-none text-left flex-grow;

      & span {
        @apply block pb-1 font-sans text-xs font-bold leading-none tracking-widest;
      }
    }

    & svg {
      @apply w-[11px] h-auto pointer-events-none;
    }
  }

  & :global(.open-filter) {
    @apply relative z-30 bg-white rounded-b overflow-hidden;

    & :global(.overlay) {
      @apply absolute w-full h-full z-40 pointer-events-none;
    }

    & :global(ul) {
      @apply overflow-y-scroll max-h-[416px] relative;

      &::-webkit-scrollbar {
        @apply w-1;
      }
      &::-webkit-scrollbar-track {
        @apply bg-black-25;
      }
      &::-webkit-scrollbar-thumb {
        @apply bg-black-100;
      }
      &::-webkit-scrollbar-thumb:hover {
        @apply bg-black-75;
      }
    }

    & :global(.button-wrapper) {
      @apply px-4 pb-3 mt-2;

      & :global(.button) {
        @apply w-full font-sans;
        &:global(.dim) {
          @apply opacity-20;
        }
      }
    }
  }

  & :global(.radio-wrapper) {
    @apply cursor-pointer h-10 px-4 flex items-center;
    @apply text-base select-none hover:bg-summer-green-25;

    & label {
      @apply cursor-pointer;
    }

    & input {
      @apply hidden;
    }
  }
}
