.container {
  @apply bg-white border-4 border-summer-green-100 border-dashed p-8 rounded-lg mb-8;

  & :global(.no-gap) {
    @apply mb-8;
  }

  & :global(.block-gap) {
    @apply pt-8 font-bold text-summer-green-100;
  }
}
