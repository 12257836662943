.wrapper {
  & :global(.tabs-wrapper) {
    & :global(.badge-wrapper) {
      @apply inline-block;
      & span {
        @apply whitespace-nowrap inline-block py-1 px-2 ml-3 rounded-full bg-summer-green-100 font-sans text-sm;
      }
    }

    &:global(.style-hero) {
      & ul {
        @apply flex justify-center;
      }

      & :global(.tab) {
        @apply text-base md:text-lg font-bold font-serif border-t-4 border-transparent;
        @apply flex flex-nowrap justify-between items-center md:justify-center;
        @apply cursor-pointer;

        @apply w-1/2 max-w-[320px] px-4 pt-3 pb-5;

        &:global(.is-active) {
          @apply bg-summer-green-23 border-summer-green-100;
        }
      }
    }

    &:global(.style-welcome) {
      @apply bg-summer-green-23 pt-8 text-center;

      & ul {
        @apply w-[calc(100%-2rem)] md:w-auto rounded-md;
        @apply p-1 md:p-2 inline-flex bg-summer-green-50 md:rounded-lg text-left;
      }

      & :global(.tab) {
        @apply text-base px-4 py-4 md:py-4 md:px-12 rounded-[4px] md:rounded-md font-serif font-bold leading-6;
        @apply flex flex-nowrap justify-between md:items-center;
        @apply cursor-pointer w-1/2 md:w-auto relative;

        & a {
          @apply inline-block leading-[21px];
        }

        &:global(.is-active) {
          @apply bg-white;
        }

        & :global(.badge-wrapper) {
          @apply absolute -top-4 right-2 md:relative md:top-0 md:right-0;
        }
      }
    }
  }

  & :global(.list-wrapper) {
    @apply bg-summer-green-23 min-h-64;
  }

  & :global(.no-content) {
    @apply col-span-4 text-center py-12 max-w-1xl mx-auto min-h-25;

    & h2 {
      @apply text-[28px] leading-10 mb-4;
    }
  }

  & :global(.categories-wrapper) {
    @apply max-w-8xl box-content px-4 mx-auto md:px-8 pt-6 md:pt-8;

    & ul {
      @apply inline-block mr-2;
    }

    & :global(.selected) {
      @apply inline-block py-1.5 px-2 mr-2 mb-2 bg-summer-green-50 rounded font-bold uppercase text-xs tracking-[1px];
    }

    & :global(.toggle-modal) {
      @apply font-bold font-sans text-base underline cursor-pointer;

      & svg {
        @apply inline-block w-4 h-4 ml-2;
      }
    }
  }

  & :global(.event-grid) {
    &:global(.archive) {
      @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 lg:gap-x-13 lg:gap-y-10;
    }

    @apply max-w-8xl box-content px-4 py-4 mx-auto md:px-8 md:py-8 lg:pb-12 overflow-hidden;
  }

  & :global(.nav-wrapper) {
    @apply text-center pb-12 md:pb-20 px-4;
  }

  & :global(.booking-card) {
    @apply bg-white mb-4 p-6 rounded-lg;
    @apply lg:flex;

    & h2 {
      @apply font-sans text-1.5xl md:text-2.5xl pb-2;
    }

    & p {
      @apply text-base;
    }

    & :global(.content) {
      @apply max-w-1xl lg:w-1/2 mb-4 lg:mb-0;
    }

    & :global(.meta) {
      @apply p-4 bg-summer-green-100/10 text-base;
      @apply lg:mx-10 w-full lg:w-1/2 md:flex mb-4 lg:mb-0;

      & div {
        @apply md:w-1/2 mb-4 md:mb-0;
      }

      & span {
        @apply block;
      }

      & :global(.label) {
        @apply text-xs font-medium font-sans tracking-wider uppercase block mb-1;
      }
    }

    & :global(.actions) {
      @apply w-full lg:max-w-[200px];
      & :global(.btn) {
        @apply block text-center w-full;
        & svg {
          @apply inline-block w-7 h-auto mr-1;
        }
      }
    }
  }
}
