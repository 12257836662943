.wrapper {
  &:global(.bg-color-summer-green) {
    @apply bg-summer-green-23;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-green) {
    @apply bg-green-25;
    @apply py-10 md:py-16;
  }

  &:global(.bg-color-white) {
    @apply bg-white;
    @apply py-10 md:py-16;
  }

  & :global(.inner-wrapper) {
    @apply box-content px-4 mx-auto md:px-8 max-w-8xl;
  }

  & :global(.columns) {
    @apply grid grid-cols-1 md:grid-cols-2 gap-10;
  }

  & h2 {
    @apply text-2.5xl md:text-4.5xl mb-4 flex items-center lg:items-baseline relative;
  }

  & :global(.tooltip) {
    @apply relative h-7;
    & > svg {
      @apply w-7 h-7 ml-2 cursor-pointer;
    }

    &:global(.active) {
      &::before {
        @apply absolute content-[""] border-solid border-transparent;

        @apply left-5 -bottom-4 -translate-x-1/2;
        @apply border-l-10 border-r-10 border-b-10 border-t-0;
        @apply border-b-black-100;
      }
      &:global(.right) {
        &::before {
          @apply left-10 bottom-auto top-1/2 -translate-x-0 -translate-y-1/2;
          @apply border-l-0 border-r-0 border-b-0;
          @apply border-t-10 border-r-10 border-b-10;
          @apply border-b-transparent border-r-black-100;
        }
      }
    }
  }

  & h3 {
    @apply text-xl md:text-2.5xl;
  }

  & a {
    @apply font-bold text-base underline;
  }

  & :global(.new-items) {
    @apply text-sm font-sans rounded-full py-1 px-2 bg-summer-green-100 font-bold ml-2.5;
    @apply no-underline;
  }

  & :global(.event) {
    @apply block mt-4 no-underline mb-6;

    & figure {
      @apply relative aspect-w-4 aspect-h-3 overflow-hidden mb-4;
    }

    & strong {
      @apply inline-block;
    }
  }

  & :global(.articles) {
    @apply list-none mb-2;
  }

  & :global(.article) {
    @apply border-b border-black-100/25 py-4;

    & a {
      @apply block;
    }

    &:last-of-type {
      @apply border-none;
    }
  }

  & :global(.event),
  & :global(.article) {
    & a {
      @apply no-underline;
    }

    & :global(.tag) {
      @apply text-xs font-bold tracking-wider uppercase block mb-0.5 md:mb-2;
    }

    & h3 {
      @apply font-sans text-2xl font-medium line-clamp-3;
      @apply hover:text-hover-effect text-has-hover-effect;
    }

    & h4 {
      @apply font-sans text-xl font-medium line-clamp-3;
      @apply hover:text-hover-effect text-has-hover-effect;
    }

    & :global(.event-meta) {
      &:first-of-type {
        @apply mt-2;
      }

      &:last-of-type {
        @apply mb-1;
      }

      @apply block font-normal text-base text-black-100/80;
    }

    & :global(.dateline) {
      @apply block font-normal mt-3 text-sm text-black-100/80;
    }

    & :global(.terms) {
      @apply pt-3;
    }

    & :global(.term) {
      @apply inline-block py-1.5 px-2 mr-2 mb-2 bg-summer-green-23 rounded font-bold uppercase text-xs tracking-[1px];
    }
  }

  & :global(.no-result) {
    & :global(.content) {
      @apply my-8 rounded-lg shadow-xl bg-white p-8 w-full lg:w-[88%] text-center;

      & h3 {
        @apply text-2xl mb-2;
      }
      & p {
        @apply text-base mb-4;
      }

      & :global(.btn) {
        @apply whitespace-normal;
      }
    }
  }

  & :global(.no-settings) {
    @apply relative;

    & :global(.modal) {
      @apply absolute top-0 left-0 w-full h-full flex items-center justify-center;
    }

    & :global(.content) {
      @apply rounded-lg shadow-xl bg-white p-8 w-[88%] text-center;

      & h3 {
        @apply text-2xl mb-2;
      }
      & p {
        @apply text-base mb-4;
      }

      & :global(.btn) {
        @apply whitespace-normal;
      }
    }

    & :global(.skeleton) {
      @apply pt-6 pb-3 border-b border-black-100/10;

      &:first-of-type {
        @apply pt-4;
      }

      &:last-of-type {
        @apply border-0;
      }

      & * {
        @apply block h-4.5 mb-2 bg-summer-green-25;
      }

      & :global(.tag) {
        @apply w-15;
      }

      & :global(.heading) {
        @apply h-7 w-3/4;
      }

      & :global(.dateline) {
        @apply h-5 w-1/4 mb-4;
      }

      & :global(.terms) {
        @apply h-auto bg-transparent;

        & :global(.term) {
          @apply inline-block h-[26px] mb-0 mr-2 rounded-md w-1/4;
          &:nth-of-type(2) {
            @apply w-1/5;
          }

          &:nth-of-type(3) {
            @apply w-[15%];
          }
        }
      }
    }
  }
}
