.wrapper {
  @apply box-content max-w-screen-sm px-4 mx-auto mt-10 md:max-w-screen-md lg:max-w-4xl sm:px-10 lg:px-17 md:mt-15 lg:mt-20;

  &:global(.inserted) {
    @apply mt-0;
  }

  &:global(.is-sticky) {
    @apply hidden md:block;
  }

  & :global(.image-wrapper) {
    @apply hidden w-72 sm:block;

    & :global(.inner-wrapper) {
      @apply relative h-0 overflow-hidden w-72 pt-full bg-black-100/10;
    }
  }

  & :global(.text-wrapper) {
    @apply flex items-center py-6;

    &:global(.no-image) {
      @apply mx-auto;
    }

    & h2 {
      @apply pb-3 font-sans text-xl font-medium leading-snug lg:text-2xl;
    }

    & :global(.text) {
      @apply pb-6 font-sans text-base leading-relaxed;
    }
  }
}

.themes {
  &:global(.theme-white) {
    @apply bg-white;
  }

  &:global(.theme-lime) {
    @apply bg-summer-green-50;
  }

  &:global(.theme-green) {
    @apply bg-green-50;
  }

  &:global(.theme-dark-green) {
    @apply bg-green-100;
  }

  &:global(.theme-yellow) {
    @apply bg-campaign-yellow-100;
  }

  &:global(.theme-light-lime) {
    @apply bg-campaign-light-lime-100;
  }

  &:global(.theme-cyan) {
    @apply bg-dark-cyan-100;
  }

  &:global(.theme-light-cyan) {
    @apply bg-dark-cyan-50;
  }

  &:global(.theme-black) {
    @apply bg-black-100 text-white;
  }

  &:global(.theme-forest-green) {
    @apply bg-campaign-forest-green-100 text-white;
  }
}
