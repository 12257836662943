.card {
  & :global(.image-wrapper) {
    @apply relative w-full h-0 mb-3.6 overflow-hidden pt-full bg-black-100/10;

    & img {
      @apply image-has-hover-effect;
    }

    &:hover img {
      @apply image-hover-effect;
    }
  }

  & :global(.has-detail) {
    @apply cursor-pointer;
  }

  & :global(.text-wrapper) {
    @apply text-sm sm:text-base;
  }

  & :global(.position) {
    font-size: 11px;
    @apply mb-2.5 block font-sans break-words sm:text-xs;
    hyphens: auto;
  }

  & :global(.full-name) {
    @apply font-sans text-lg md:text-xl font-medium pb-1;
  }

  & button {
    @apply block underline pb-2;
  }

  & a {
    @apply inline-block underline pb-2;
  }
}
