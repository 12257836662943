.form {
  @apply w-full;
}

.search {
  @apply flex justify-between;

  & :global(.search-input) {
    @apply flex items-center px-4 border rounded mb-0 border-black-100/25 h-15;
    @apply md:mr-5 w-full;

    & input {
      @apply w-full h-full overflow-hidden text-lg focus:outline-none bg-white;
    }
  }

  &:global(.type-multi) {
    @apply flex-row;
    & :global(.search-input) {
      width: calc(100% - 80px);
    }
  }

  &:global(.type-select) {
    @apply flex-col md:flex-row;
  }

  & :global(.search-button) {
    @apply hidden md:block w-full min-w-40 h-full;
  }

  & :global(.adv-select) {
    @apply relative font-sans text-lg font-normal text-left rounded;
    @apply border border-black-100/25 h-15;
    @apply mt-6 md:mt-0;
    @apply w-full min-w-[280px] md:mr-5;

    & :global(label) {
      @apply absolute z-10 font-sans text-xs font-bold leading-none tracking-widest pointer-events-none left-5 top-3;
    }

    & svg {
      @apply w-[11px] h-auto absolute top-0 right-0 mt-6.5 mr-4 pointer-events-none;
    }
  }

  & :global(.adv-button) {
    @apply h-15 font-sans font-normal md:mr-5;
  }

  & :global(.button-wrapper) {
    @apply flex;
  }
}

.multi {
  @apply mt-5 md:mt-7.5;
}

.map {
  @apply w-full;

  &:global(.map-active) {
    @apply mt-3;
  }

  &:global(.no-choices) {
    @apply mt-0;
  }
}

.filters {
  @apply grid grid-cols-1 gap-5 md:gap-y-7.5 md:grid-cols-2 xl:grid-cols-3;

  &:global(.has-map:not(.has-choices)) {
    @apply mb-5 lg:mb-7.5;
  }

  &:global(.four-filters) {
    @apply xl:grid-cols-4;
  }
}

.activeChoices {
  & :global(.choice-wrapper) {
    @apply pt-5 lg:pt-7.5;
  }

  & :global(.label) {
    @apply inline-block mr-3.6 md:mr-5 text-xl font-bold align-middle;
  }

  & :global(ul) {
    @apply inline-block;
  }

  & :global(.active-item) {
    @apply inline-flex items-center h-8 last:mr-3.6 md:last:mr-5 px-3 py-2 mb-2.5 mr-3 font-sans text-xs font-medium tracking-wider no-underline uppercase whitespace-nowrap rounded cursor-pointer leading-tight bg-black-100/10 hover:bg-black-100/20;
  }

  & :global(.clear) {
    @apply inline-block text-base font-medium underline cursor-pointer;
  }
}

.toggleWrapper {
  @apply w-full md:w-auto lg:ml-auto mt-3;

  &:global(.no-choices) {
    @apply mt-0;
  }

  & :global(.button) {
    @apply flex flex-row items-center justify-center w-full md:w-33 text-base bg-white border rounded h-13 text-black-100 border-black-25 hover:text-black-75;
  }
}

.checkboxWrapper {
  @apply relative select-none h-14;

  &:global(.is-open) {
    @apply z-40;
  }

  & :global(.checkboxes) {
    @apply absolute top-0 left-0 w-full bg-white border border-black-100/25 rounded;

    &:global(.has-choices) {
      @apply border-summer-green-100 z-10;
    }

    &:global(.has-shadow) {
      @apply shadow-md;
    }
  }

  & header {
    @apply flex items-center justify-between px-4 py-3 cursor-pointer;

    & :global(.current-choice) {
      @apply text-lg leading-none pointer-events-none;

      & span {
        @apply block pb-1 font-sans text-xs font-bold leading-none tracking-widest;
      }
    }

    & :global(.caret) {
      @apply w-[11px] h-auto absolute top-0 right-0 mt-6.5 mr-4 pointer-events-none;
      @apply transition-transform duration-[400ms];
    }

    & :global(.clear-filter) {
      @apply flex items-center;

      & :global(.square) {
        @apply flex items-center justify-center w-5 h-5 mr-5 rounded bg-summer-green-100;
      }
    }
  }

  &:global(.is-open header .caret) {
    @apply transform rotate-180;
  }

  & :global(.open-filter) {
    @apply relative z-30 bg-white rounded-b overflow-hidden;

    & :global(.overlay) {
      @apply absolute w-full h-full max-h-36 z-40 pointer-events-none;
      &:after {
        @apply absolute bottom-0 left-0 h-4 rounded-b content-[""];
        width: calc(100% - 4px);
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          #fff
        );
      }
    }

    & :global(ul) {
      @apply pl-4 overflow-y-scroll max-h-36 relative;

      & li {
        @apply mb-2 text-base;
      }

      &::-webkit-scrollbar {
        @apply w-1;
      }
      &::-webkit-scrollbar-track {
        @apply bg-black-25;
      }
      &::-webkit-scrollbar-thumb {
        @apply bg-black-100;
      }
      &::-webkit-scrollbar-thumb:hover {
        @apply bg-black-75;
      }
    }

    & :global(.button-wrapper) {
      @apply px-4 pb-3 mt-2;

      & :global(.button) {
        @apply w-full font-sans;
        &:global(.dim) {
          @apply opacity-20;
        }
      }
    }
  }
}

.checkbox {
  @apply flex items-center cursor-pointer;
  & :global(.square) {
    @apply w-5 h-5 border rounded-sm flex items-center justify-center border-black-100;
  }

  &:global(.is-checked .square) {
    @apply bg-summer-green-100 border-summer-green-100;
  }

  & :global(.label) {
    @apply pl-3 max-w-[85%];
  }
}

.selectWrapper {
  @apply relative block px-0 mx-0 font-sans text-lg font-normal text-left rounded;
  @apply border border-black-25 h-15 md:mb-0 text-black-100;

  & label {
    @apply absolute z-10 font-sans text-xs font-bold leading-none tracking-widest pointer-events-none left-5 top-3;
  }

  & svg {
    @apply w-[11px] h-auto absolute top-0 right-0 mt-6 mr-4 pointer-events-none;
  }

  &:global(.disabled) {
    @apply text-black-50;

    & svg {
      @apply opacity-40;
    }
  }

  @screen md {
    flex: 1 1 50%;
  }
  @screen lg {
    min-width: 160px;
  }
}

.select {
  @apply absolute top-0 left-0 block w-full h-full text-lg leading-[normal] outline-none appearance-none focus:outline-none bg-white;
  @apply pt-4 pl-5 pr-9 overflow-hidden whitespace-nowrap overflow-ellipsis rounded;
}
