.card {
  @apply relative;

  &:global(.is-new) {
    &:after {
      @apply absolute content-[""] w-4 h-4 rounded-full bg-summer-green-100 -top-2 -right-2;
    }
  }

  & figure {
    @apply aspect-w-4 aspect-h-3 relative overflow-hidden rounded-t-lg;
  }

  & :global(.content) {
    @apply bg-white rounded-b-lg p-6 xl:p-8;
  }

  & :global(.tag) {
    @apply text-xs font-bold tracking-wider uppercase block mb-2;
  }

  & :global(.heading) {
    @apply md:h-[5.5rem] line-clamp-3;
  }

  & h3 {
    @apply font-sans text-xl font-medium inline;

    /* Texteffect */
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: background-size 0.3s;
  }

  & :global(.terms) {
    @apply block mt-3;
  }

  & :global(.term) {
    @apply inline-block py-1.5 px-2 mr-2 mb-2 bg-summer-green-23 rounded font-bold uppercase text-xs tracking-[1px];

    @apply font-bold !important;
    @apply no-underline !important;
    @apply ml-0 !important;
  }

  & :global(.dateline) {
    @apply block mt-3 text-sm text-black-100/80;
  }

  &:hover {
    & h3 {
      background-size: 100% 1px;
    }
  }
}
