.controller {
  @apply h-15 w-full border border-black-25 rounded;
  @apply flex justify-between items-center p-[5px];

  &:global(.has-value) {
    @apply border-summer-green-100;
  }

  & input {
    @apply h-full text-lg w-full pl-4;

    &::placeholder {
      @apply text-sm;
    }
  }

  & button {
    @apply bg-summer-green-100 font-bold font-serif text-lg;
    @apply h-full rounded-[3px] w-18 text-center;
    @apply flex justify-center items-center;
    @apply flex-none;

    & svg {
      @apply w-6 h-auto;
    }
  }

  & :global(.clear-search) {
    @apply bg-summer-green-100 w-5 h-5 p-[5px] rounded mx-4 cursor-pointer;
    @apply flex-none;

    & svg {
      @apply w-full h-auto;
    }
  }
}
